import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLogin from 'hooks/useLogin';
const AuthRedirect = () => {
  const location = useLocation();
  const history = useNavigate();
  const { superLogin } = useLogin();
  useEffect(() => {
    if (location.pathname === '/') {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      if (token) {
        superLogin(token);
      }
    }
  }, [location, history]);

  return null;
};

export default AuthRedirect;
