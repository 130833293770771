import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
// login option 3 routing
const LoginAuth = Loadable(lazy(() => import('views/Authentication/LoginAuth/Login')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

export const newRoutes = {
  path: '/',
 // element: <MainLayout />,
  children: [
    {
      path: '/new_login',
      element: <LoginAuth />
    },
    
  ]
};

