import { Outlet, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, Container } from '@mui/material';
import Header from './Header';
import Footer from 'ui-component/Footer';
import { useHeaderVisibility } from '../../hooks/useHeaderVisibility';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const theme = useTheme();
  const shouldDisplayHeader = useHeaderVisibility();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      {shouldDisplayHeader && (
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            flex: 'none'
          }}
        >
          <Container>
            <Toolbar>
              <Header />
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <Box sx={{ flex: '1 1 auto', overflow: 'auto' }} marginTop={shouldDisplayHeader ? '80px' : '0px'}>
        <Outlet />
      </Box>
      <Box sx={{ flex: 'none', background: !shouldDisplayHeader ? '#E9E9E9' : '' }}>
        <Footer showFilings={shouldDisplayHeader} />
      </Box>
    </Box>
  );
};

export default MinimalLayout;
