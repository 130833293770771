// assets
import { IconChartArea, IconMessage, IconPictureInPicture } from '@tabler/icons-react';
import {
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconLayoutGrid,
  IconDevicesPlus,
  IconUserHeart,
  IconBoxModel,
  IconTag,
  IconSettingsBolt,
  // IconPictureInPicture,
  // IconMessage,
  IconDeviceAudioTape
} from '@tabler/icons-react';
import { isShowTeamMenu } from 'utils/common';

// constant
const icons = {
  IconDashboard,
  IconLayoutGrid,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconDevicesPlus,
  IconChartArea
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const getPanel = () => {
  return {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: '总览',
        type: 'item',
        url: '/panel/dashboard',
        icon: icons.IconDashboard,
        breadcrumbs: false,
        isAdmin: false
      },
      {
        id: 'statistics',
        title: '统计',
        type: 'item',
        url: '/panel/statistics',
        icon: IconChartArea,
        breadcrumbs: false,
        isAdmin: true
      },
      {
        id: 'modelsquare',
        title: '模型广场',
        type: 'item',
        url: '/panel/modelsquare',
        icon: icons.IconLayoutGrid,
        breadcrumbs: false,
        isAdmin: false
      },
      // {
      //   id: 'model-adjustment',
      //   title: '模型微调',
      //   type: 'item',
      //   url: '/panel/model/fine_tuning',
      //   icon: IconSettingsBolt,
      //   breadcrumbs: false,
      //   isAdmin: false,
      //   disabled: false
      // },
      // {
      //   id: 'model-deploy',
      //   title: '模型服务',
      //   type: 'item',
      //   url: '/panel/model/fine_service',
      //   icon: icons.IconDevicesPlus,
      //   breadcrumbs: false,
      //   isAdmin: false
      // },
      // {
      //   id: 'user_index_distribution',
      //   title: '数据面板管理',
      //   type: 'item',
      //   url: '/panel/user_index_distribution',
      //   icon: IconEaseInControlPoint,
      //   breadcrumbs: false,
      //   isAdmin: true
      // },
      {
        id: 'exploration_center',
        title: '体验中心',
        type: 'collapse',
        // type: 'item',
        // url: '/panel/exploration_center',
        icon: IconUserHeart,
        breadcrumbs: false,
        isAdmin: false,
        children: [
          {
            id: 'chat_bot',
            title: '对话聊天',
            type: 'item',
            url: '/panel/exploration_center',
            icon: IconMessage,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'text2image',
            title: '文生图',
            type: 'item',
            url: '/panel/exploration_center/text2img',
            icon: IconPictureInPicture,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'text2language',
            title: '文生语音',
            type: 'item',
            url: '/panel/exploration_center/chattts',
            icon: IconDeviceAudioTape,
            breadcrumbs: false,
            isAdmin: false
          }
        ]
      },
      {
        id: 'channel',
        title: '端点',
        type: 'item',
        url: '/panel/channel',
        icon: icons.IconSitemap,
        breadcrumbs: false,
        isAdmin: true
      },
      {
        id: 'token',
        title: '令牌',
        type: 'item',
        url: '/panel/token',
        icon: icons.IconKey,
        breadcrumbs: false
      },
      {
        id: 'log',
        title: '日志',
        type: 'item',
        url: '/panel/log',
        icon: icons.IconArticle,
        breadcrumbs: false
      },
      {
        id: 'redemption',
        title: '兑换',
        type: 'item',
        url: '/panel/redemption',
        icon: icons.IconCoin,
        breadcrumbs: false,
        isAdmin: true
      },
      // {
      //   id: 'topup',
      //   title: '充值',
      //   type: 'item',
      //   url: '/panel/topup',
      //   icon: icons.IconGardenCart,
      //   breadcrumbs: false
      // },
      {
        id: 'modelsquare-manage',
        title: '模型广场管理',
        type: 'item',
        url: '/panel/modelsquare-manage',
        icon: IconBoxModel,
        breadcrumbs: false,
        isAdmin: true
      },
      {
        id: 'modeltag/manage',
        title: '模型标签管理',
        type: 'item',
        url: '/panel/modeltag/manage',
        icon: IconTag,
        breadcrumbs: false,
        isAdmin: true
      },
      {
        id: 'user',
        title: '用户',
        type: 'item',
        url: '/panel/user',
        icon: icons.IconUser,
        breadcrumbs: false,
        isAdmin: true
      },
      ...(isShowTeamMenu()
        ? [
          {
            id: 'team_manage',
            title: '团队管理',
            type: 'item',
            url: '/panel/team_manage',
            icon: IconBoxModel,
            breadcrumbs: false,
            isAdmin: false
          }
        ]
        : []),
      {
        id: 'profile',
        title: '我的',
        type: 'item',
        url: '/panel/profile',
        icon: icons.IconUserScan,
        breadcrumbs: false,
        isAdmin: false
      },
      {
        id: 'setting',
        title: '设置',
        type: 'item',
        url: '/panel/setting',
        icon: icons.IconAdjustments,
        breadcrumbs: false,
        isAdmin: true,
        isRoot: true
      }
    ]
  };
};

export { getPanel };
