// material-ui
import { Link, Container, Box, Grid, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import NationalEmblemImg from 'assets/images/national_emblem.png';
import { useHeaderVisibility } from 'hooks/useHeaderVisibility';
// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const Footer = ({ showFilings }) => {
  const siteInfo = useSelector((state) => state.siteInfo);
  const displayOneApi = useHeaderVisibility();
  const theme = useTheme();
  return (
    <>
      {showFilings && (
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '64px' }}>
          <Box sx={{ textAlign: 'center' }}>
            {siteInfo.footer_html ? (
              <div className="custom-footer" dangerouslySetInnerHTML={{ __html: siteInfo.footer_html }}></div>
            ) : (
              <>
                <Grid
                  sx={{
                    marginBottom: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      marginBottom: '0px'
                    }
                  }}
                  style={{
                    height: '15px',
                    lineHeight: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#aaa',
                    justifyContent: 'center',
                    marginTop: '20px',
                    flexWrap: 'wrap'
                  }}
                >
                  <img style={{ width: '12px', objectFit: 'contain', marginRight: '5px' }} src={NationalEmblemImg} />
                  京公网安备:11010802038875号 <span style={{ margin: '0px 5px' }}>|</span>
                  <Link href="https://beian.miit.gov.cn/?spm=a21bo.21814703.1997523009.44.319b11d9uOV8gY#/Integrated/index">
                    京ICP备:2020035859号-1
                  </Link>{' '}
                  <span style={{ margin: '0px 5px' }}>|</span>
                  <span>Copyright © Perfxlab 澎峰科技 All Rights Reserved</span>
                </Grid>
              </>
            )}
          </Box>
        </Container>
      )}
    </>
  );
};

export default Footer;
